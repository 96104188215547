import React from 'react'
import {Image, Container, Col, Row} from 'react-bootstrap';

import introArrows from '../website-images/introArrows.svg';
import virtualAssistant from '../website-images/virtual-assistant.webp';
import customerService from '../website-images/customer-service-representative.webp';
import dataEntry from '../website-images/data-entry-specialist.webp';
import ourProject1 from '../website-images/our-project-1.png';
import graphicDesignPortfolio from '../website-images/graphic-design-portfolio.pdf';







export default function OurProjectsPage() {
  return (


<div className='adminSupport-page-container'>

        <div className='adminSupport-page-banner'></div>
        <div className='adminSupport-page-title'>
            <h1><span>Our</span> Projects</h1>
            <p className='m-5 text-center'>Here, you will find a showcase of the various projects we have undertaken and completed to the highest standards. Browse through our portfolio to see the quality of our work and the range of services we offer.</p>
        </div>
        
        {/* CARDS SECTION */}
        <Container className='adminSupport-page-cards-container'>
            <Row>

                <Col className='adminSupport-page-card-item col-12 col-lg-6'>
                <a href={graphicDesignPortfolio} target="_blank">
                    <div className='adminSupport-page-card-item-img'>
                        <Image src={ourProject1} alt='our-project-1.png' fluid />
                    </div>
                </a>
                </Col>

                <Col className='adminSupport-page-card-item col-12 col-lg-6'>
                <div className='adminSupport-page-card-item-title'>
                    <h2>Graphic Design Portfolio</h2>
                    <p>We created various graphic designs such as social media posters, logos, banner ads, vector arts, & calling cards. </p>

                    <p>Technologies used on these projects are the following</p>
                    <ul className='adminSupport-page-card-item-list'>
                        <li>Adobe Photoshop</li>
                        <li>Adobe Illustrator</li>
                    </ul>
                </div>
                </Col>


                {/* <Col className='adminSupport-page-card-item col-12 order-2 col-lg-6 order-lg-1'>
                <div className='adminSupport-page-card-item-title'>
                    <h2>Customer Service Representative</h2>
                    <p>Customer services is at the heart of any business, and getting it right can never be undervalued. Whilst sales and marketing attracts new customers, it is how you treat your existing customers that really drives business success.
                    <br/>
                    <br/>
                    Creaxon can provide staffing solutions across all customer support functions, including (but not limited to) the following:</p>
                    <ul className='adminSupport-page-card-item-list'>
                        <li>Technical Helpdesk</li>
                        <li>Customer Service Representative</li>
                        <li>Customer Support Management</li>
                        <li>Online Customer Services</li>
                        <li>Product Management Specialists</li>
                        <li>Experienced Service Assistants</li>
                    </ul>
                </div>
                </Col>

                <Col className='adminSupport-page-card-item col-12 order-1 col-lg-6 order-lg-2'>
                <div className='adminSupport-page-card-item-img'>
                    <Image src={customerService} alt='customer-service.jpg' fluid />
                </div>
                </Col>

                <Col className='adminSupport-page-card-item col-12 order-3 col-lg-6'>
                <div className='adminSupport-page-card-item-img'>
                    <Image src={dataEntry} alt='data-entry.jpg' fluid />
                </div>
                </Col>

                <Col className='adminSupport-page-card-item col-12 order-4 col-lg-6'>
                <div className='adminSupport-page-card-item-title'>
                    <h2>Data Entry Specialist</h2>
                    <p>If you are looking for knowledgeable and experienced data entry specialists, Creaxon Inc. has the expertise you need to provide you with highly suitable candidates.
                    <br/>
                    <br/>
                    Creaxon can provide staffing solutions across all customer support functions, including (but not limited to) the following:</p>
                    <ul className='adminSupport-page-card-item-list'>
                        <li>Data Entry Experts</li>
                        <li>Data Mining Specialist</li>
                        <li>Customer Support Management</li>
                        <li>Online Customer Services</li>
                        <li>Product Management Specialists</li>
                        <li>Experienced Service Assistants</li>
                    </ul>
                </div>
                </Col> */}

            </Row>
        </Container>
    </div>




  )
}

