import React from 'react';

import Button from '@mui/material/Button';
import arrowIcon from '../website-images/introArrows.svg';
import APARSpecialist from '../website-images/APAR-Specialist.webp';
import salesRepresentative from '../website-images/sales-representatives.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';

const CareersPage = () => {
  return (
    <div id='careers-page-main-container'>
      <Helmet>
            <title>Creaxon Inc | Careers</title>
            <meta 
            name="description" 
            content="Creaxon Inc. is a leading offshore staffing company providing offshore recuitment solutions to some of the world's leading organisations and brands."
            />
            <link rel="canonical" href='https://www.creaxoninc.com/careers' />
        </Helmet>

      <header id='careers-page-header'>
        
        <h1 className='careers-page-header-title-text'>Careers</h1>
        <p className='careers-page-header-text'>
          Creaxon Inc. is a leading offshore staffing company providing offshore recuitment solutions to some of the world's leading organisations and brands. 
          Our staff are either home-based or working from our premium office facilities around the Philippines, and 
          reporting directly to our clients who are primarily based in Australia, USA, and UK.
        </p>
      </header>

      <div id='career-page-job-items-main-container'>

        <section id='accounts-payable-recievable-specialist' className='careers-page-job-items-container'>
          <div className='careers-page-job-items-image'><img src={APARSpecialist} alt='APAR-specialist' className='careers-page-job-item-image' /></div>
          <div className='careers-page-job-items-text-container'>
            <div className='careers-page-job-title-container'>
              <div className='careers-page-job-title-icon-container'><img src={arrowIcon} alt='job title icon' className='careers-page-job-title-icon' /></div>
              <h2 className='careers-page-job-title'>Service Coordinator</h2>
            </div>
            <div className='careers-page-job-description-container'>
              <p className='careers-page-job-description'>
              As a Service Coordinator, your desk transforms into a command center of excellence. Your primary mission: to liaise directly with our clients and internal teams, orchestrating service appointments, managing inquiries, and upholding Sparky Mate's commitment to excellence.
              </p>
            </div>
            <div className='careers-page-job-items-button-container'>
              <Button 
              variant="contained" 
              fullWidth 
              className='careers-page-job-items-button' 
              href="https://www.jobstreet.com.ph/en/job/service-coordinator-13196156?jobId=jobstreet-ph-job-13196156&sectionRank=1&token=0~5d3d27bc-28fa-4a7a-a707-701bb7f38b9f&searchPath=%2Fcreaxon-jobs&fr=SRP%20View%20In%20New%20Tab"
              target="_blank"
              >
                Apply Now
              </Button>
            </div>
          </div>
        </section>

        <section id='accounts-payable-recievable-specialist' className='careers-page-job-items-container'>
          <div className='careers-page-job-items-image'><img src={salesRepresentative} alt='sales-representatives' className='careers-page-job-item-image' /></div>
          <div className='careers-page-job-items-text-container'>
            <div className='careers-page-job-title-container'>
              <div className='careers-page-job-title-icon-container'><img src={arrowIcon} alt='job title icon' className='careers-page-job-title-icon' /></div>
              <h2 className='careers-page-job-title'>Technical Sales Specialist</h2>
            </div>
            <div className='careers-page-job-description-container'>
              <p className='careers-page-job-description'>
              Embark on a transformative journey with an Australian proprietary company, a trailblazer since 1969 in the supply and calibration of cutting-edge scientific measuring instruments. Bridge the gap between innovation and real-world application, shaping the landscapes of research, industry, and testing facilities globally. As an essential part of our dynamic internal sales team based in Makati, your role is far beyond transactions. Infuse your interactions with a confident and outgoing personality as you forge deep connections with clients across diverse sectors. Revel in the satisfaction of introducing our exceptional products to the world.
              </p>


            </div>
            <div className='careers-page-job-items-button-container'>
              <Button 
              variant="contained" 
              fullWidth 
              className='careers-page-job-items-button' 
              href="https://www.jobstreet.com.ph/en/job/technical-sales-specialist-13196180?jobId=jobstreet-ph-job-13196180&sectionRank=2&token=0~5d3d27bc-28fa-4a7a-a707-701bb7f38b9f&searchPath=%2Fcreaxon-jobs&fr=SRP%20View%20In%20New%20Tab"
              target="_blank"
              >
                Apply Now
              </Button>
            </div>
          </div>
        </section>

      </div>
    </div>
  );
};

export default CareersPage;