import Button from "react-bootstrap/Button";


export default function Banner (props){


    return (

        <div className="banner-div" id="home">
            <div className="banner-title">
                <div className="banner-heading">
                    <h1>Take your <span>Business</span></h1>
                    <h2> to the next level with <span>Us.</span></h2>
                </div>            
                <div className="banner-button">
                    <Button className="contactUs-btn" onClick={props.onShow}>REQUEST A CONSULTATION</Button>
                </div>
            </div>
        </div>

    );


}