
import Nav  from "react-bootstrap/Nav";
import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import navLogo from '../website-images/navLogo.webp';



export default function AppNavbar(props) {

  // CHANGE NAVBAR BACKGROUND TO GRAY WHEN NOT IN HOMEPAGE
  const [grayNavBar, setgrayNavBar] = useState(false);
  let location = useLocation();

  useEffect(() =>{

  function grayNavigation () {
    if(location.pathname !== '/'){
      setgrayNavBar(true);
    }
  };

  grayNavigation();


  }, [grayNavBar, location]);


  // RESIZE NAVBAR WHEN SCROLLED 
  const [navbar, setNavbar] = useState(false);

  const changeSize = () =>{
    
    if(window.scrollY >= 200){
      setNavbar(true)
    }else{
      setNavbar(false)
    }
  }
  window.addEventListener('scroll', changeSize);

  // CLOSE NAVBAR WHEN TOUCHED OUTSIDE (VANILLA JAVASCRIPT)
  // window.onload = function () {
  //   document.addEventListener("touchstart", function (event) {
  //     // IF THE TOUCHED ELEMENT ISN"T A CHILD OF THE NAVBAR, IT"S BE CLOSED IF OPENED
  //     if (!event.target.closest("#navbar-main") && document.getElementById("navbarSupportedContent").classList.contains("show")) {
  //       document.getElementById("hamburger_menu_button").click();
  //     }
  //   });
  // }


  // CLOSE NAVBAR WHEN TOUCHED OUTSIDE (REACT HOOKS)
  const box = useRef(null);
  useClickedOutside(box);

  function useClickedOutside(ref){

    useEffect(() => {

      // Function for click event
      const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target) && document.getElementById('navbarSupportedContent').classList.contains('show')) {
          document.getElementById('hamburger_menu_button').click();

        }
      }
 
      // Adding click event listener
      document.addEventListener("touchstart", handleOutsideClick);
      return () => document.removeEventListener("swipe up" && "touchstart", handleOutsideClick);

      }, [ref])

  }

  return (
      
    <Navbar className={navbar ? 'navbar active' : navbar || grayNavBar ? 'gray-navbar' : grayNavBar } id="navbar-main" variant="dark" fixed="top" expand="xl" collapseOnSelect="true" ref={box}>
    <div className="nav-logo-div"><Navbar.Brand href="/"><img src={navLogo} alt="navLogo.png" className="nav-logo d-inline-block align-top img-fluid" /></Navbar.Brand></div>
    <Navbar.Toggle aria-controls="hamburger_menu_button" id="hamburger_menu_button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" />
    <Navbar.Collapse className="navbar-collapse" id="navbarSupportedContent">
    <Nav className="me-auto nav justify-content-end container-fluid">
            <Nav.Item><Nav.Link href="/" className="nav-item .active">HOME</Nav.Link></Nav.Item>
            <Nav.Item>
              <Nav.Link href="/our-services" className="nav-item .active">OUR SERVICES</Nav.Link>
              <div className="nav-item-dropdown">
                <button><a href='/admin-support'>Admin Support</a></button>
                <button><a href="/marketing">Marketing</a></button>
                <button><a href="/web-development">Web Development</a></button>
                <button><a href="/sales">Sales</a></button>
                <button><a href="creative-design">Creative Design</a></button>
                <button><a href="/accounting">Accounting</a></button>
              </div>
            </Nav.Item>
            <Nav.Item><Nav.Link href="/how-it-works" className="nav-item .active">HOW IT WORKS</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link href="/careers" className="nav-item .active">CAREERS</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link href="/about-us" className="nav-item .active">ABOUT US</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link href="/contact-us" className="nav-item .active">CONTACT US</Nav.Link></Nav.Item>
    </Nav>
    </Navbar.Collapse>
    </Navbar>

    );

}


