import { useState, React } from 'react';
import {Form, Button, Container, Image, Row, Col } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import axios from 'axios';

import paperAirplaneIcon from '../website-images/paper-airplane-icon-yellow.svg';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';


function ContactUsPage(props) {


  const [companyName, setcompanyName] = useState('');
  const [contactName, setcontactName] = useState('');
  const [contactEmailAddress, setcontactEmailAddress] = useState('');
  const [contactPhoneNumber, setcontactPhoneNumber] = useState('');
  const [typeOfService, setTypeOfService] = useState('');
  const [requestDate, setRequestDate] = useState(new Date().toISOString().split('T')[0]);

  const [isLoading, setIsLoading] = useState(false);


  function submitContactForm(event) {

    let formData = {
        companyName: companyName,
        contactName: contactName,
        contactEmailAddress: contactEmailAddress,
        contactPhoneNumber: contactPhoneNumber,
        requestDate: requestDate,
        typeOfService: typeOfService
    };

    event.preventDefault();
    sendContact(formData);
    setIsLoading(true);
    //console.log(formData);

  }

  function formReset() {
      setcompanyName('');
      setcontactName('');
      setcontactEmailAddress('');
      setcontactPhoneNumber('');
      setRequestDate(new Date().toISOString().split('T')[0]);
      setTypeOfService('');
  }

  function sendContact(formData) {
    axios.post('https://mailer-api.creaxoninc.com/creaxon-mailer', formData)
    .then(response => {
        let result = response.data;
        if (result.status === "Message Recieved" || result.status === "Message Sent") {
            setIsLoading(false);
            props.notifyToastSuccess();
            console.log(result);
            formReset();
        }else {
            props.notifyToastFailed();
            console.log(result);
            setIsLoading(false);
        }
      });
}


  return (
    <div className='contactUs-page-container'>
      <Helmet>
          <title>Creaxon Inc | Contact Us</title>
          <meta 
          name="description" 
          content="How can we help? Send us a message and we'll get back to you as soon as possible."
          />
          <link rel="canonical" href='https://www.creaxoninc.com/contact-us' />
      </Helmet>
      <div className='contactUs-page-title-div'>
        <h1>Contact <span>Us</span></h1>
        <p>How can we help? <span className='how-can-we-help'>Send us a message and we'll get back to you as soon as possible.</span></p>

        <Container className='contactUs-page-form-div'>
        <Row>

          <Col className='contactUs-page-form-image col-12 order-2 col-lg-5 order-lg-1'>
            <Image src={paperAirplaneIcon} alt='paper-airplane-icon.svg' className='contactUs-page-img' fluid/>
            </Col>        
          
          <Col className='contactUs-page-form-col col-12 order-1 col-lg-7 order-lg-2'>
            <Form onSubmit={submitContactForm} method="POST" className='contactUs-page-form'>
            <Form.Group className="mb-3" >
              <Form.Control type="text" 
              id='company-name'
              placeholder="Company Name" 
              size="lg"
              className='contactUs-page-companyName' 
              value={companyName} 
              onChange= {(e) => setcompanyName(e.target.value)} 
              />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Control type="text" 
              id='full-name'
              placeholder="Full Name" 
              size="lg" 
              className='contactUs-page-fullName'
              required 
              value={contactName} 
              onChange= {(e) => setcontactName(e.target.value)} 
              />
            </Form.Group>

            <div>
            <Row>
              <Col className='col-12 col-lg-6'>
                <Form.Group className="mb-3" >
                  <Form.Control type="email" 
                  id='email-address'
                  placeholder="Email Address" 
                  size="lg" 
                  className='contactUs-page-email' 
                  required
                  value={contactEmailAddress} 
                  onChange= {(e) => setcontactEmailAddress(e.target.value)} 
                  />
                </Form.Group>
              </Col>

              <Col className='col-12 col-lg-6'>
                <Form.Group className="mb-3" >
                  <Form.Control type="tel" 
                  id='contact-number'
                  placeholder="Contact Number" 
                  size="lg" 
                  className='contactUs-page-contactNumber'
                  required 
                  value={contactPhoneNumber} 
                  onChange= {(e) => setcontactPhoneNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            </div>

            <Form.Group>
              <Form.Label className='contactUs-page-preferred-date-label'>Preferred Date of Service</Form.Label>
            <TextField
              className='contactUs-page-preferred-date'
              id="date"
              label="Preferred Date of Consultation"
              type="date"
              fullWidth
              margin='normal'
              variant="outlined"
              required={true}
              name='requestDate'
              defaultValue={requestDate}
              onChange={(event) => {setRequestDate(event.target.value);}}
               />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Control as="textarea" 
              id='message'
              placeholder="How can we help you?" 
              rows={3} 
              size="lg" 
              className='contactUs-page-message' 
              value={typeOfService} 
              onChange= {(e) => setTypeOfService(e.target.value)}
              />
            </Form.Group>
            
            <div className='contactUs-page-submit-button-div'>
              <Button 
              variant="primary" 
              type="submit" 
              className='contactUs-page-submit-button' 
              disabled={isLoading} 
              onClick={
                companyName.length === 0 ? null 
                : contactName.length === 0 ? null
                : contactEmailAddress.length === 0 ? null 
                : contactPhoneNumber.length === 0 ? null
                : requestDate.length === 0 ? null
                : props.eventTrack.bind(this, "contact_us_page_form_submission", "forms_submissions", "contact_us_page_form")
            }
              >
              {isLoading ? "Loading..." : "Submit"}
              </Button>
            </div>
            </Form>
          </Col>
        </Row>
        </Container>
      </div>
    </div>
  )
}

export default ContactUsPage