import React from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap';

import introArrows from '../website-images/introArrows.svg';
import webDesigner from '../website-images/web-designer.webp';
import fullStackDeveloper from '../website-images/full-stack-developer.webp';
import projectManager from '../website-images/project-manager.webp';
import systemAdministrator from '../website-images/system-administrator.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';


export default function WebDevelopmentPage() {
  return (
    <div className='webDevelopment-page-container'>
        <Helmet>
          <title>Web Development Services | Creaxon Inc</title>
          <meta 
          name="description" 
          content=" We have the best people who can help you develop and launch a website that can help your business compete in this digital age."
          />
          <link rel="canonical" href='https://www.creaxoninc.com/web-development' />
      </Helmet>

        <div className='webDevelopment-page-banner'></div>
        <div className='webDevelopment-page-title'><h1>Web Development</h1></div>

        <Container className='webDevelopment-page-subtitle'>
            <Row className='webDevelopment-page-subtitle-row'>
            <Col className='webDevelopment-page-description col-12 col-md-6'>
                <p>Wow your website visitors at first glance by having an eye-catching website to capture their attention. Good website design boosts the customer's confidence and creates trust to do business with you. 
                <br/>
                <br/>
                Our Web Development services will enable you to do just that. We have the best people who can help you develop and launch a website that can help your business compete in this digital age.</p>
            </Col>
            <Col className='webDevelopment-page-quote col-12 col-md-6'>
                <hr/>
                <h2>“ 75% of consumers admit to making judgements on a company's credibility based on the company's website design”</h2>
                <br/>
                <h2 className='webDevelopment-page-quote-author'>- Stanford Web Credibility Research</h2>
                <hr/>
                <div className='webDevelopment-page-description-logo'><Image src={introArrows} alt='introArrows.svg' className='webDevelopment-page-img'/></div>
            </Col>
            </Row>
        </Container>

        {/* CARDS SECTION */}
        <Container className='webDevelopment-page-cards-container'>
        <Row>

            <Col className='webDevelopment-page-card-item col-12 col-lg-6'>
            <div className='webDevelopment-page-card-item-img'>
                <Image src={webDesigner} alt='web-designer.jpg' fluid/>
            </div>
            </Col>

            <Col className='webDevelopment-page-card-item col-12 col-lg-6'>
            <div className='webDevelopment-page-card-item-title'>
                <h2>Web Designer</h2>
                <p>Web designers use their creative skills to design, build and improve websites. They understand user experience and are able to build websites that are easy to understand, navigate, and use, and adhere to design standards and specifications.
                <br/>
                <br/>
                Creaxon can provide staffing solutions for web desgin services with skills ranging from (but not limited to) the following:</p>
                <ul>
                    <li>Adobe Photoshop</li>
                    <li>Adobe Illustrator</li>
                    <li>Busness Orieted Design</li>
                    <li>Client Specifications</li>
                    <li>Custom Mockups</li>
                    <li>Mobile Web Design</li>
                    <li>Web Design Projects</li>
                </ul>
            </div>
            </Col>

            <Col className='webDevelopment-page-card-item col-12 order-2 col-lg-6 order-lg-1'>
            <div className='webDevelopment-page-card-item-title'>
                <h2>Full Stack Developer</h2>
                <p>Full Stack Developers write full-stack web applications. They are tasked with developing front-end & back-end components that integrates with each other to create a full working websites/applications.
                <br/>
                <br/>
                Creaxon Inc. can source for you SEO Specialists with skills ranging (but not limited to) the following:</p>
                <ul>
                    <li>HTML & CSS</li>
                    <li>React.js</li>
                    <li>Bootstrap</li>
                    <li>Javascript</li>
                    <li>Node.js</li>
                    <li>Restful API</li>
                    <li>NoSQL & SQL</li>
                </ul>
            </div>
            </Col>

            <Col className='webDevelopment-page-card-item col-12 order-1 col-lg-6 order-lg-2'>
            <div className='webDevelopment-page-card-item-img'>
                <Image src={fullStackDeveloper} alt='full-stack-developer.jpg' fluid/>
            </div>
            </Col>

            <Col className='webDevelopment-page-card-item col-12 order-3 col-lg-6'>
            <div className='webDevelopment-page-card-item-img'>
                <Image src={projectManager} alt='project-manager.jpg' fluid/>
            </div>
            </Col>

            <Col className='webDevelopment-page-card-item col-12 order-4 col-lg-6'>
            <div className='webDevelopment-page-card-item-title'>
                <h2>Project Manager</h2>
                <p>At Creaxon Inc., we ensure that we stringently evaluate candidates for Project Management roles for their leadership, attention to detail, and most especially for their ability to communicate effectively with key stakeholders.
                <br/>
                <br/>
                We can help source the right Project Management candidates with skills ranging from (but not limited to) the following:</p>
                <ul>
                    <li>Leadership</li>
                    <li>Team Management</li>
                    <li>Negotiation</li>
                    <li>Organization</li>
                    <li>Risk Management</li>
                    <li>Scrum</li>
                </ul>
            </div>
            </Col>

            <Col className='webDevelopment-page-card-item col-12 order-last col-lg-6 order-lg-5'>
            <div className='webDevelopment-page-card-item-title'>
                <h2>System Administrator</h2>
                <p>System Administrators are responsible for effective provisioning, installation, configuration, operation, and maintenance of system hjardware, software and related infrastructure.
                <br/>
                <br/>
                Creaxon Inc. will work closely with you to recruit high-potential candidates from our extensive job pool, in order to secure a Systems Administrator with skills ranging from (but not limited to) the following:</p>
                <ul>
                    <li>Information Systems</li>
                    <li>Operating Systems</li>
                    <li>Server Administration</li>
                    <li>Social network advertising</li>
                    <li>System Administration</li>
                    <li>Windows Administration</li>
                </ul>
            </div>
            </Col>
            
            <Col className='webDevelopment-page-card-item col-12 order-5 col-lg-6 order-lg-last'>
            <div className='webDevelopment-page-card-item-img'>
                <Image src={systemAdministrator} alt='system-administrator.jpeg' fluid/>
            </div>
            </Col>

        </Row>
      </Container>


    </div>
  )
}

