import React from 'react';

import Button from 'react-bootstrap/Button';

import footerLogo from '../website-images/CreaxonSVGWhite.svg';
import facebook from '../website-images/facebook.svg';
import linkedIn from '../website-images/linkedIn.svg';
import instagram from '../website-images/instagram.svg';
import youtube from '../website-images/youtube.svg';
import footerEmail from '../website-images/footer-email.svg';
import footerPhone from '../website-images/footer-phone.svg';

const Footer = (props) => {
    return (
        <div className='footer-container'>
            <div className="footer-logo-container">
                <img src={footerLogo} alt='footer-logo' className='creaxon-logo-image'/>
            </div>
            <div className='footer-text'>
                <div className='navigation-contact-footer'>
                    <div className='creaxon-inc'>
                        <h4>CREAXON INC.</h4>
                        <a href ="/" className='footer-nav'>Home</a>
                        <a href ="/our-services" className='footer-nav'>Our Services</a>
                        <a href ="/how-it-works" className='footer-nav'>How it Works</a>
                        <a href ="/careers" className='footer-nav'>Careers</a>
                        <a href ="/about-us" className='footer-nav'>About Us</a>
                    </div>

                    <div className='footer-contact-us'>
                        <h4>CONTACT US</h4>
                        <div className='footer-email-phone'>
                            <Button variant="light" className='footer-button' href='mailto:info@creaxoninc.com'><img src={footerEmail} alt='footer-email' onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_email_button")} />info@creaxoninc.com</Button>
                            <Button variant="light" className='footer-button' href='tel:8882192787'> <img src={footerPhone} alt='footer-phone' onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_call_button")} />(02)82945492</Button>
                        </div>
                        <br/>
                        <a href ="https://www.facebook.com/creaxoninc" target='_blank' rel="noreferrer"><img src={facebook} alt='facebook' onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_facebook_link")} /></a>
                        <a href ="https://www.linkedin.com/company/creaxon-inc/" target='_blank' rel="noreferrer"><img src={linkedIn} alt='linked-in' onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_linkedin_link")} /></a>
                        <a href ="https://www.instagram.com/creaxoninc/" target='_blank' rel="noreferrer"><img src={instagram} alt='instagram' onClick={props.eventTrack.bind(this, "footer_section", "button_clicks", "footer_section_instagram_link")}/></a>
                        <a href ="https://www.youtube.com/" target='_blank' rel="noreferrer"><img src={youtube} alt='youtube' /></a>
                    </div>
                </div>

                <div className='footer-office-address'>
                    <h4>OFFICE ADDRESS</h4>
                    <p>AUSTRALIA <span>Unit 13/63 Ricky Way, Epping 3076 Australia</span></p>
                    <p>PHILIPPINES <span>7/F Filipino Building, 135 Dela Rosa Street. Legazpi Village, Makati, 1229 Kalakhang Maynila, Philippines.</span></p>
                </div>
            </div>
            <hr />
            <p className='copyright'>© Creaxon Inc. | All Rights Reserved</p>
        </div>
    );
};

export default Footer;