import introArrows from '../website-images/introArrows.svg';
import Aos from 'aos';
import { useEffect } from 'react';

export default function Introducton (){

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, [])

    return (

            <div className="intro-div">                           
                <div className="intro-background container">
                    <div className="row">
                        <div className="col col"></div>
                        <div className="intro-title-div col-12 col-lg-6 text-md-center text-lg-start" data-aos='fade-zoom-in'>
                            <h2>Hire <span>skilled</span> and</h2><h2><span>dedicated</span> remote</h2><h2>staff</h2>
                        </div>
                        <div className="intro-arrowSolid-1 col d-none d-lg-block" data-aos="fade-up"><img src={introArrows} className="img-fluid" alt="creaxon-arrows" data-aos-once="true" data-aos-delay="150"/></div>

                        <div className="col-12 col-lg-6"></div>
                        <div className="intro-paragraph-div col col-12 col-lg-6 text-md-center" data-aos="fade" data-aos-easing='ease-in-out' data-aos-delay="200">
                            <p>Build your team with top-performing high-quality and professional offshore staff. We connect you with  a large pool of highly skilled employees whilst we handle the supervision, training, HR, and payroll.</p>

                            <br></br>
                            <p>With 30-days terms and no lock-in contracts, join successful business owners turning to "supported offshoring" to scale their business yearly.</p>
                        </div>
                    </div>

                    <div className="intro-arrowSolid-2 d-lg-none"><img src={introArrows} className="img-fluid" alt="creaxon-arrows"/></div>
                </div>
            </div>

    );


}