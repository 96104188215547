import React from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap';

import introArrows from '../website-images/introArrows.svg';
import accountant from '../website-images/accountant.webp';
import accountsPayableSpecialist from '../website-images/accounts-payable-specialist.webp';
import accountsReceivableSpecialist from '../website-images/accounts-receivable-specialist.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';

export default function AccountingPage() {
  return (
    <div className='accounting-page-container'>
        <Helmet>
            <title>Accounting Services | Creaxon Inc</title>
            <meta 
            name="description" 
            content="Our Finance Services will take your worry away on managing the tedious tasks on keeping your financial records on track with the help of our accountants and specialists."
            />
            <link rel="canonical" href='https://www.creaxoninc.com/accounting' />
        </Helmet>

        <div className='accounting-page-banner'></div>
        <div className='accounting-page-title'><h1>Accounting</h1></div>

        <Container className='accounting-page-subtitle'>
            <Row className='accounting-page-subtitle-row'>
            <Col className='accounting-page-description col-12 col-md-6'>
                <p>Ensuring that financial records are properly kept are vital for every business. 
                <br/>
                <br/>
                Our Finance Services will take your worry away on managing the tedious tasks on keeping your financial records on track with the help of our accountants and specialists while maintaining confidentiality on the sensitive information for every financial transactions. </p>
            </Col>
            <Col className='accounting-page-quote col-12 col-md-6'>
                <hr/>
                <h2>"Everything starts with recording your financials accurately. "</h2>
                <br/>
                <h2 className='accounting-page-quote-author'>-  Trisha Vilale 
                <br/>Accounts Receivable Analyst</h2>
                <hr/>
                <div className='accounting-page-description-logo'><Image src={introArrows} alt='creaxon-arrows' className='accounting-page-img'/></div>
            </Col>
            </Row>
        </Container>

        {/* CARDS SECTION */}
        <Container className='accounting-page-cards-container'>
        <Row>

            <Col className='accounting-page-card-item col-12 col-lg-6'>
            <div className='accounting-page-card-item-img'>
                <Image src={accountant} alt='accountant.jpg' fluid/>
            </div>
            </Col>

            <Col className='accounting-page-card-item col-12 col-lg-6'>
            <div className='accounting-page-card-item-title'>
                <h2>Accountant</h2>
                <p>Accountants help businesses maked critical financial decisions by collecting, tracking, and correcting the company's finances. They are responsible for financial audits, reconciling bank statements, and ensuring financial records are accurate throughout the year.
                <br/>
                <br/>
                Creaxon Inc recruit professionals can source for you highly qualified certified accountants (and help you save up to 75 % on labour costs), with skills ranging from (but not limited to) the following:</p>
                <ul>
                    <li>Invoice Specialists</li>
                    <li>Certified Practicing Accountants</li>
                    <li>Auditors</li>
                    <li>Bookkeepers</li>
                    <li>Financial Planner</li>
                    <li>Financial Advisors</li>
                </ul>
            </div>
            </Col>

            <Col className='accounting-page-card-item col-12 order-2 col-lg-6 order-lg-1'>
            <div className='accounting-page-card-item-title'>
                <h2>Accounts Payable Specialist</h2>
                <p>Creaxon Inc. specialises in sourcing and helping manage highly-skilled professionals. Keeping your payments up to date will establish credibility with your suppliers and smoothen your cash flows. Accounts payable specialists generally complete payments and control expenses by receiving, processing, verifying and reconciling invoice.
                <br/>
                <br/>
                The candidate database search is highly stringent, to ensure that the Accounts Payable candidates we put are proficient with skills ranging from (but not limited to) the following:</p>
                <ul>
                    <li>Accounting Services</li>
                    <li>Invoicing</li>
                </ul>
            </div>
            </Col>

            <Col className='accounting-page-card-item col-12 order-1 col-lg-6 order-lg-2'>
            <div className='accounting-page-card-item-img'>
                <Image src={accountsPayableSpecialist} alt='accounts-payable-specialist.jpg' fluid/>
            </div>
            </Col>

            <Col className='accounting-page-card-item col-12 order-3 col-lg-6'>
            <div className='accounting-page-card-item-img'>
                <Image src={accountsReceivableSpecialist} alt='accounts-receivable-specialist.jpg' fluid/>
            </div>
            </Col>

            <Col className='accounting-page-card-item col-12 order-4 col-lg-6'>
            <div className='accounting-page-card-item-title'>
                <h2>Accounts Receivable Specialist</h2>
                <p>Accounts receivables specialists working remotely from the Philippines can take the pressure off of you and financial resources, while saving up to 755 on labour costs. Our expert recruitment managers will guide you through the recruitment, selection and post appointment processes of your chosen candidate.
                <br/>
                <br/>
                Creaxon Inc can assist you sourcing for highly qualified Accounts Receivable Specialitsts with skills ranging from (but not limited to) the following:</p>
                <ul>
                    <li>Accounts Receivables</li>
                    <li>Collections</li>
                    <li>Compensation accounts</li>
                    <li>customer accounts</li>
                    <li>Reporting</li>
                    <li>Accounts Receivables specialist accounts services</li>
                </ul>
            </div>
            </Col>
        </Row>
      </Container>

    </div>

    
  )
}
