import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';

import introArrows from '../website-images/introArrows.svg';
import marketingAssistant from '../website-images/marketing-assistant.webp';
import seoSpecialist from '../website-images/seo-specialist.webp';
import goodAdwordsSpecialist from '../website-images/google-adwords-specialist.webp';
import socialMediaMarketing from '../website-images/social-media-marketing.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';

export default function Marketing() {
  return (
    <div className='marketing-page-container'>
        <Helmet>
            <title>Marketing Services | Creaxon Inc</title>
            <meta 
            name="description" 
            content="Rocket boost your brand value by developing a custom strategy to target your desired audience. 
            Every brand needs to have an online presence to increase awareness and drive customers and prospects."
            />
            <link rel="canonical" href='https://www.creaxoninc.com/marketing' />
        </Helmet>
        
      <div className='marketing-page-banner'></div>
      <div className='marketing-page-title'><h1>Digital Marketing</h1></div>

      <Container className='marketing-page-subtitle'>
            <Row className='marketing-page-subtitle-row'>
            <Col className='marketing-page-description col-12 col-md-6'>
                <p>Tell us about yourself so we can tell the world who you are. 
                <br/>
                <br/>
                Rocket boost your brand value by developing a custom strategy to target your desired audience. Every brand needs to have an online presence to increase awareness and drive customers and prospects. Our marketing services will help you achieve your desired goals through research and data-fueled marketing solutions. </p>
            </Col>
            <Col className='marketing-page-quote col-12 col-md-6'>
                <hr/>
                <h2>“ You cannot buy engagement. You have to build engagement”</h2>
                <br/>
                <h2 className='marketing-page-quote-author'>- Tara-Nicholle Nelson</h2>
                <hr/>
                <div className='marketing-page-description-logo'><Image src={introArrows} alt='introArrows.svg' className='marketing-page-img'/></div>
            </Col>
            </Row>
        </Container>

        {/* CARDS SECTION */}
        <Container className='marketing-page-cards-container'>
            <Row>

                <Col className='marketing-page-card-item col-12 col-lg-6'>
                <div className='marketing-page-card-item-img'>
                    <Image src={marketingAssistant} alt='marketing-assistant.jpg' fluid/>
                </div>
                </Col>

                <Col className='marketing-page-card-item col-12 col-lg-6'>
                <div className='marketing-page-card-item-title'>
                    <h2>Marketing Assistant</h2>
                    <p>Marketing assistants play a vital role to the Marketing and Communication functions, and can take a range of administrative pressures off the Marketing team. Tasks such as data entry, reporting, preparing marketing materials, competitor analysis, and market research can be carried out by a Marketing Assistant.
                    <br/>
                    <br/>
                    Creaxon can source for you Marketing Assistants with skills ranging from (but not limited to) the following:</p>
                    <ul className='marketing-page-card-item-list'>
                        <li>Communications</li>
                        <li>Digital Marketing</li>
                        <li>Email Marketing</li>
                        <li>Encoding</li>
                        <li>Market research</li>
                        <li>Virtual marketing</li>
                        <li>Social Media Posting and Scheduling</li>
                    </ul>
                </div>
                </Col>

                <Col className='marketing-page-card-item col-12 order-2 col-lg-6 order-lg-1'>
                <div className='marketing-page-card-item-title'>
                    <h2>SEO Specialist</h2>
                    <p>An SEO Specialist must work acroos all areas of content strategy, keyword strategy and link building with the sole objective of improving your rankings on all major search networks such as Google, Bing and Yahoo. SEO also requires an in-depth understanding of Search Engine Marketing (SEM) strategy and execution.
                    <br/>
                    <br/>
                    Creaxon Inc. can source for you SEO Specialists with skills ranging (but not limited to) the following:</p>
                    <ul className='marketing-page-card-item-list'>
                        <li>On-page optimization</li>
                        <li>Off-page optimization</li>
                        <li>Link building</li>
                        <li>SEO reporting</li>
                        <li>Keyword strategy</li>
                        <li>Search Engine Marketing (SEM)</li>
                    </ul>
                </div>
                </Col>

                <Col className='marketing-page-card-item col-12 order-1 col-lg-6 order-lg-2'>
                <div className='marketing-page-card-item-img'>
                    <Image src={seoSpecialist} alt='seo-specialist.jpg' fluid/>
                </div>
                </Col>

                <Col className='marketing-page-card-item col-12 order-3 col-lg-6'>
                <div className='marketing-page-card-item-img'>
                    <Image src={goodAdwordsSpecialist} alt='google-adwords-specialist.jpg' fluid/>
                </div>
                </Col>

                <Col className='marketing-page-card-item col-12 order-4 col-lg-6'>
                <div className='marketing-page-card-item-title'>
                    <h2>Google Adwords Specialist</h2>
                    <p>An AdWorde Specialist is responsible for handling PPC, CPC and CPA, Marketing campaigns for customers, S/he should be able to easily recognize and endorse projecte that can lead to the goals and progress of the company campaigns.
                    <br/>
                    <br/>
                    Creaxon Inc. can source for you Google Adwordc Specialist with skills ranging from the following:</p>
                    <ul className='marketing-page-card-item-list'>
                        <li>Manage PPC profiles on Google AdWords, and Yahoo, Bing and other search sites if required</li>
                        <li>Assiet in the management and tracking of keyword bids, expenditure cape and other primary account metrics.</li>
                        <li>Manage and retain keyword list updates.</li>
                        <li>Provide ideas for a creative copywriting.</li>
                        <li>Product Management Specialists</li>
                        <li>Provide suggestions and inoorporate techniques for keyword resources, marketing structuring, network display and other aspects of pay per click searching in conjunction with the clients objectives.</li>
                    </ul>
                </div>
                </Col>

                <Col className='marketing-page-card-item col-12 order-last col-lg-6 order-lg-5'>
                <div className='marketing-page-card-item-title'>
                    <h2>Social Media Marketing</h2>
                    <p>Social media is an important component of any digital marketing strategy today. The sourcing, creation and distribution of social content across multiple social networks on a consistent basis, can be time consuming. Outsourcing these functions can take a considerable amount of pressure off of the local marketing function.
                    <br/>
                    <br/>
                    Creaxon Inc. can source for you Social Media Marketer with skills ranging from the following:</p>
                    <ul className='marketing-page-card-item-list'>
                        <li>Brand marketing</li>
                        <li>Community building</li>
                        <li>Digital marketing</li>
                        <li>Social network advertising</li>
                        <li>Facebook for business</li>
                        <li>Twitter handling</li>
                        <li>YouTube optimisation</li>
                    </ul>
                </div>
                </Col>
                
                <Col className='marketing-page-card-item col-12 order-5 col-lg-6 order-lg-last'>
                <div className='marketing-page-card-item-img'>
                    <Image src={socialMediaMarketing} alt='social-media-marketing.jpg' fluid/>
                </div>
                </Col>

            </Row>
        </Container>
    </div>

  )
}

