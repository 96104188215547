import { React, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import TextField from '@mui/material/TextField';

import comapanyNameFormIcon from '../website-images/companyFormIcon.svg';
import contactNumberFormIcon from '../website-images/contactNumberFormIcon.svg';
import emailFormIcon from '../website-images/emailFormIcon.svg';
import contactNameFormIcon from '../website-images/contactPersonFormIcon.svg';

import axios from 'axios';

const ConsulationModal = (props) => {


    const [companyName, setcompanyName] = useState('');
    const [contactName, setcontactName] = useState('');
    const [contactEmailAddress, setcontactEmailAddress] = useState('');
    const [contactPhoneNumber, setcontactPhoneNumber] = useState('');
    const [typeOfService, setTypeOfService] = useState('');
    const [requestDate, setRequestDate] = useState(new Date().toISOString().split('T')[0]);

    const [isLoading, setIsLoading] = useState(false);


    function submitContactForm(event) {

        let formData = {
            companyName: companyName,
            contactName: contactName,
            contactEmailAddress: contactEmailAddress,
            contactPhoneNumber: contactPhoneNumber,
            requestDate: requestDate,
            typeOfService: typeOfService
        };

        event.preventDefault();
        sendContact(formData);
        setIsLoading(true);
        //console.log(formData);

    }

    function formReset() {
        setcompanyName('');
        setcontactName('');
        setcontactEmailAddress('');
        setcontactPhoneNumber('');
        setRequestDate(new Date().toISOString().split('T')[0]);
        setTypeOfService('');
    }

    function sendContact(formData) {
        axios.post('https://mailer-api.creaxoninc.com/creaxon-mailer', formData)
        .then(response => {
            let result = response.data;
            if (result.status === "Message Recieved" || result.status === "Message Sent") {
                setIsLoading(false);
                props.onHide();
                props.notifyToastSuccess();
                console.log(result);
                formReset();
            }else {
                props.notifyToastFailed();
                console.log(result);
                setIsLoading(false);
            }
          });
    }

    return (
        <div>
            <Modal
            {...props} 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='modal-component-container'
            >
                <Modal.Header closeButton closeVariant={'white'} className='blue'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <p>Schedule a consultation with us!</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Hello There,</h4>
                    <p>
                        Kindly fill out the form below to book a consultation with us. Our team will reach out to you accordingly afterwards.
                    </p>
                    <Form onSubmit={submitContactForm} method="POST">
                        <Row className="align-items-center">
                            <Col xs="12">
                                <Form.Label htmlFor="inlineFormInput" visuallyHidden>Company Name</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text><img src={comapanyNameFormIcon} alt='company-name-icon' style={{width: '20px', height: '20px'}} /></InputGroup.Text>
                                    <Form.Control 
                                        id="inlineFormInputGroup" 
                                        placeholder="Company Name" 
                                        required={false}
                                        name='companyName'
                                        value={companyName}
                                        onChange={(event) => {setcompanyName(event.target.value)}}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs="12" lg="4">
                                <Form.Label htmlFor="inlineFormInput" visuallyHidden>Contact Person</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text><img src={contactNameFormIcon} alt='contact-name-icon' style={{width: '20px', height: '20px'}} /></InputGroup.Text>
                                    <Form.Control 
                                        id="inlineFormInputGroup" 
                                        placeholder="Contact Person" 
                                        required={true}
                                        name='contactName'
                                        value={contactName}
                                        onChange={(event) => {setcontactName(event.target.value);}}
                                    />
                                </InputGroup>
                            </Col>
                                <Col xs="12" lg="4">
                                <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>Email Address</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text><img src={emailFormIcon} alt='email-icon' style={{width: '20px', height: '20px'}} /></InputGroup.Text>
                                    <Form.Control 
                                        id="inlineFormInputGroup" 
                                        type="email"
                                        placeholder="Email Address" 
                                        required={true}
                                        name='contactEmailAddress'
                                        value={contactEmailAddress}
                                        onChange={(event) => {setcontactEmailAddress(event.target.value);}}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs="12" lg="4">
                                <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>Contact Number</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text><img src={contactNumberFormIcon} alt='contact-number-icon' style={{width: '20px', height: '20px'}} /></InputGroup.Text>
                                    <Form.Control 
                                        id="inlineFormInputGroup" 
                                        type="number"
                                        placeholder="Contact Number" 
                                        required={true}
                                        name='contactPhoneNumber'
                                        value={contactPhoneNumber}
                                        onChange={(event) => {setcontactPhoneNumber(event.target.value);}}
                                    />
                                </InputGroup>
                            </Col>

                            <Col xs="12">
                            <TextField
                                className='form-text-fields'
                                id="date"
                                label="Preferred Date of Consultation"
                                type="date"
                                InputLabelProps={{
                                shrink: true,
                                }}
                                fullWidth
                                margin='normal'
                                variant="outlined"
                                required={true}
                                name='requestDate'
                                defaultValue={requestDate}
                                onChange={(event) => {setRequestDate(event.target.value);}}
                            />
                            </Col>
                            
                            <Col xs="12">
                                <Form.Label htmlFor="inlineFormInput" visuallyHidden>Message</Form.Label>
                                <Form.Control
                                    className="mb-2"
                                    id="inlineFormInput"
                                    placeholder="Message"
                                    as={'textarea'}
                                    rows={5}
                                    name='typeOfService'
                                    value={typeOfService}
                                    onChange={(event) => {setTypeOfService(event.target.value);}}
                                />
                            </Col>
                            <Col xs="12">
                                <div className='button-container'>
                                    <Button 
                                        type="submit" 
                                        className="mb-2 full-button" 
                                        style={{borderRadius: '0px'}} 
                                        onClick={
                                            companyName.length === 0 ? null 
                                            : contactName.length === 0 ? null
                                            : contactEmailAddress.length === 0 ? null 
                                            : contactPhoneNumber.length === 0 ? null
                                            : requestDate.length === 0 ? null
                                            : props.eventTrack.bind(this, "consultation_modal", "forms_submissions", "homepage_consultation_modal")
                                        }
                                        disabled={isLoading}> {isLoading ? "Loading..." : "Submit"}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} style={{borderRadius: '0px', border: 'none' , margin: '0 auto', backgroundColor: 'transparent', color: '#d23435'}}>Close</Button>
                </Modal.Footer>
    </Modal>
    
        </div>
    );
};
export default ConsulationModal;
