import React from 'react';
import Button from 'react-bootstrap/Button';


import contactUsImage from '../website-images/contactUs.webp';
import contactUsImageSmall from '../website-images/contactUs-750x500.webp';


const ContactUs = (props) => {
    return (
        <div className='contact-us-container' id='contact-us'>
             <div className="contact-us-image-container"><img src={contactUsImage} srcSet={`${contactUsImageSmall} 768w, ${contactUsImage} 1200w`} alt='contact-us' className='contact-us-image'/></div>

                <div className="contact-us-text">

                    <h2>Contact <span id='header-bold'>Us</span></h2>

                    <div className='row1'>

                        <div className='contact-details-container'>
                            <h3><strong>Email</strong></h3>
                            <p>info@creaxoninc.com</p>
                        </div>
                        
                        <div className='contact-details-container'>
                            <h3><strong>Social Media</strong></h3>
                            <a href='https://www.facebook.com/creaxoninc' className='contact-us-socials' target='_blank' rel="noreferrer">facebook</a>
                            <a href='https://www.linkedin.com/company/creaxon-inc/' className='contact-us-socials' target='_blank' rel="noreferrer">linkedIn</a>
                        </div>

                    </div>

                    <div className='contact-details-container address-container'>
                        <h3><strong>Address</strong></h3>
                            <div className='actual-address'>
                                <div className='row2'>
                                    <p className='address-item'>Unit 13/63 Ricky Way, Epping 3076 Australia.</p>
                                    <p className='address-item'>7/F Filipino Building, 135 Dela Rosa Street. Legazpi Village, Makati, 1229 Kalakhang Maynila, Philippines.</p>
                                </div>
                            </div>

                    </div>

                    <Button variant="primary" size="md" className='request-button' onClick={props.onShow}>REQUEST A CONSULTATION</Button>
                </div>

        </div>
    );
};
export default ContactUs;
