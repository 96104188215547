import React from 'react';

import howItWorksGirlImage from '../website-images/how-it-works-girl.webp';
import briefingImage from '../website-images/briefingImage.webp';
import shortlistImage from '../website-images/shortlist-icon.webp';
import selectionAndAppointmentImage from '../website-images/selection-and-appointment-icon.webp';
import postAppointmentImage from '../website-images/post-appointment-icon.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';

function HowItWorksPage(props) {
    return (
        <div className='how-it-works-main-container'>
            <Helmet>
                <title>Creaxon Inc | How It Works</title>
                <meta 
                name="description" 
                content="We tailor the recruitment process to your specific requirements and the role you are seeking to recruit. 
                You can be actively involved in the recruitment process or simply let us take care of it."
                />
                <link rel="canonical" href='https://www.creaxoninc.com/how-it-works' />
            </Helmet>
            <div className='main-content'>

                <div id='content-image'>
                    <img src={howItWorksGirlImage} alt='how-it-works-girl' className='main-content-image'/>
                </div>

                <div id='content-text'>
                    <h1 className='text-header' id='how-it-works-header'>How it Works</h1>
                    <h2 className='text-sub-header' id='how-it-works-sub-header'>See How Easy it is to Hire for your Business</h2>
                    <p className='text-content'>
                        The process of selecting offshore staff is more or less the ​same as hiring employees domestically. 
                        We tailor the ​recruitment process to your specific requirements and the ​role you are seeking to recruit. 
                        You can be actively involved ​in the recruitment process or simply let us take care of it.
                    </p>
                </div>
            </div>

            <div className='how-it-works-cards-container'>

                <div className='how-it-works-cards' id='briefing'>
                    <div className='icon-container'><img src={briefingImage} alt='briefing-icon' className='how-it-works-icons'/></div>
                    <div className='card-title'>Briefing</div>
                    <div className='card-text-container'>
                        <p className='card-texts'>Brief us on the criteria ​you are looking for and ​provide us a job ​description.</p>
                        <p className='card-texts'>You can be as involved ​in the process, or we ​can take care of it for ​you.</p>
                    </div>
                </div>

                <div className='how-it-works-cards' id='shortlist'>
                    <div className='icon-container'><img src={shortlistImage} alt='shortlist-icon' className='how-it-works-icons'/></div>
                    <div className='card-title'>Shortlist</div>
                    <div className='card-text-container'>
                        <p className='card-texts'>We search for the most ​qualified candidates.</p>
                        <p className='card-texts'>We provide you the ​shortlisted candidate.</p>
                        <p className='card-texts'>We coordinate and ​schedule a virtual ​interview between you ​and the shortlisted ​candidates.</p>
                    </div>
                </div>

                <div className='how-it-works-cards' id='selection-and-appointment'>
                    <div className='icon-container'><img src={selectionAndAppointmentImage} alt='selection-and-appointment-icon' className='how-it-works-icons'/></div>
                    <div className='card-title'>Selection & Appointment</div>
                    <div className='card-text-container'>
                        <p className='card-texts'>You select your ​preferred candidate.</p>
                        <p className='card-texts'>We negotiate and ​finalize the ​remuneration and terms ​with the candidate and ​complete the ​paperworks required for ​employment.</p>
                    </div>
                </div>

                <div className='how-it-works-cards' id='post-appointment'>
                    <div className='icon-container'><img src={postAppointmentImage} alt='post-appointment-icon' className='how-it-works-icons'/></div>
                    <div className='card-title'>Post Appointment</div>
                    <div className='card-text-container'>
                        <p className='card-texts'>Our team helps us ​comply with the needs ​of your team here in the ​Philippines.</p>
                        <p className='card-texts'>We manage the payroll.</p>
                        <p className='card-texts'>We assist with managing ​any HR issues that may ​arise.</p>
                        <p className='card-texts'>We assist provision of ​specific ​hardware/software for ​your team.</p>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default HowItWorksPage;