import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import adminSupport from '../website-images/adminSupport.webp';
import marketing from '../website-images/marketing.webp';
import webDevelopment from '../website-images/webDevelopment.webp';
import sales from '../website-images/sales.webp';
import creativeDesign from '../website-images/creative-design.webp';
import accounting from '../website-images/accounting.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';

function OurServicesPage() {
  return (
    <div className='our-services-page-container'>
      <Helmet>
          <title>Creaxon Inc | Our Services</title>
          <meta 
          name="description" 
          content="We connect you with a large pool of highly skilled employees whilst we handle the supervision, training, HR, and payroll. 
          All you need to do is focus on building your business."
          />
          <link rel="canonical" href='https://www.creaxoninc.com/our-services' />
      </Helmet>

      <div className='our-services-page-banner'></div>
      <div className='our-services-page-title'>
        <h1><span>Our</span> Services</h1>
        <p>We connect you with a large pool of highly skilled employees whilst we handle the supervision, training, HR, and payroll. All you need to do is focus on building your business.</p>
      </div>


    {/* CARDS SECTION */}
      <Container className='our-services-page-cards-container'>
        <Row>

          <Col className='our-services-page-cards-Col col-12 col-sm-6 col-lg-4'>
          <a href='/admin-support' target="_blank">
            <Card className='our-services-page-card-item'>
              <Card.Img variant="top" src={adminSupport} className='our-services-page-card-image' alt='admin support services' fluid='true' />
            <Card.Body>
              <Card.Title className='our-services-page-card-title'>Admin Support</Card.Title>
            </Card.Body>
            </Card>
            </a>
          </Col>

          <Col className='our-services-page-cards-Col col-12 col-sm-6 col-lg-4'>
          <a href='/marketing' target="_blank">
            <Card className='our-services-page-card-item'>
            <Card.Img variant="top" src={marketing} className='our-services-page-card-image' alt='marketing services' fluid='true' />
            <Card.Body>
              <Card.Title className='our-services-page-card-title'>Marketing</Card.Title>
            </Card.Body>
            </Card>
            </a>
          </Col>

          <Col className='our-services-page-cards-Col col-12 col-sm-6 col-lg-4'>
          <a href='/web-development' target="_blank">
            <Card className='our-services-page-card-item'>
            <Card.Img variant="top" src={webDevelopment} className='our-services-page-card-image' alt='web development services' fluid='true' />
            <Card.Body>
              <Card.Title className='our-services-page-card-title'>Web Development</Card.Title>
            </Card.Body>
            </Card>
            </a>
          </Col>

          <Col className='our-services-page-cards-Col col-12 col-sm-6 col-lg-4'>
          <a href='/sales' target="_blank">
            <Card className='our-services-page-card-item'>
            <Card.Img variant="top" src={sales} className='our-services-page-card-image' alt='sales representative' fluid='true' />
            <Card.Body>
              <Card.Title className='our-services-page-card-title'>Sales</Card.Title>
            </Card.Body>
            </Card>
          </a>
          </Col>

          <Col className='our-services-page-cards-Col col-12 col-sm-6 col-lg-4'>
          <a href='/creative-design' target="_blank">
            <Card className='our-services-page-card-item'>
            <Card.Img variant="top" src={creativeDesign} className='our-services-page-card-image' alt='creative design services' fluid='true' />
            <Card.Body>
              <Card.Title className='our-services-page-card-title'>Creative Design</Card.Title>
            </Card.Body>
            </Card>
          </a>
          </Col>

          <Col className='our-services-page-cards-Col col-12 col-sm-6 col-lg-4'>
          <a href='/accounting' target="_blank">
            <Card className='our-services-page-card-item'>
            <Card.Img variant="top" src={accounting} className='our-services-page-card-image' alt='accounting services' fluid='true' />
            <Card.Body>
              <Card.Title className='our-services-page-card-title'>Accounting</Card.Title>
            </Card.Body>
            </Card>
          </a>
          </Col>

        </Row>
      </Container>

    </div>
  )
}

export default OurServicesPage