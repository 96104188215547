import React, {  } from 'react';
// import {useNavigate} from 'react-router-dom';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button';


import adminSupport from '../website-images/adminSupport.webp';
import adminSupportSmall from '../website-images/adminSupport-750x500.webp'
import marketing from '../website-images/marketing.webp';
import marketingSmall from '../website-images/marketing-750x422.webp';
import webDevelopment from '../website-images/webDevelopment.webp';
import webDevelopmentSmall from '../website-images/webDevelopment-750x422.webp';
import sales from '../website-images/sales.webp';
import salesSmall from '../website-images/sales-750x500.webp';
import creativeDesign from '../website-images/creative-design.webp';
import creativeDesignSmall from '../website-images/creative-design-750x422.webp';
import accounting from '../website-images/accounting.webp';
import accountingSmall from '../website-images/accounting-750x497.webp';

const WhatWeDo = () => {

    return (
    <div className="what-we-do-container" id='services'>
        <div className="what-we-do-container">
            <div className="header-container"><h2 className="component-header-text"><span id='what'>Our</span> Services</h2></div>
            <div className='card-container'>
                <Card className="services-cards" >
                    <div className="card-image-div">
                        <Card.Img variant="top" src={adminSupport} srcSet={`${adminSupportSmall} 768w, ${adminSupport} 1200w`} className='service-card-image' alt='admin-support-card-image' />
                    </div>
                    <div className='card-overlay'/>
                    <Button variant="outline-secondary" className='service-card-button' href="/admin-support" target='_blank' alt='admin-support-card-image'>View Details</Button>
                    <Card.Body className="services-body">
                    <Card.Title className="services-title">Admin / Support</Card.Title>
                    </Card.Body>
                </Card>
                
                <Card className="services-cards" >
                    <div className="card-image-div">
                        <Card.Img variant="top" src={marketing} srcSet={`${marketingSmall} 768w, ${marketing} 1200w`} className='service-card-image' alt='marketing-card-image'/>
                    </div>
                    <div className='card-overlay'/>
                    <Button variant="outline-secondary" className='service-card-button' href="./marketing" target='_blank' alt='marketing-card-image'>View Details</Button>
                    <Card.Body className="services-body">
                    <Card.Title className="services-title">Marketing</Card.Title>
                    </Card.Body>
                </Card>
                
                <Card className="services-cards" >
                    <div className="card-image-div">
                        <Card.Img variant="top" src={webDevelopment} srcSet={`${webDevelopmentSmall} 768w, ${webDevelopment} 1200w`} className='service-card-image' alt='web-development-card-image' />
                    </div>
                    <div className='card-overlay'/>
                    <Button variant="outline-secondary" className='service-card-button' href="./web-development" target='_blank' alt='web-development-card-image'>View Details</Button>
                    <Card.Body className="services-body">
                    <Card.Title className="services-title">Web Development</Card.Title>
                    </Card.Body>
                </Card>
                
                <Card className="services-cards" >
                    <div className="card-image-div"><Card.Img variant="top" src={sales} srcSet={`${salesSmall} 768w, ${sales} 1200w`}  className='service-card-image' alt='sales-card-image'/></div>
                    <div className='card-overlay'/>
                    <Button variant="outline-secondary" className='service-card-button' href="./sales" target='_blank' alt='sales-card-image'>View Details</Button>
                    <Card.Body className="services-body">
                    <Card.Title className="services-title">Sales</Card.Title>
                    </Card.Body>
                </Card>
                
                <Card className="services-cards" >
                    <div className="card-image-div">
                        <Card.Img variant="top" src={creativeDesign} srcSet={`${creativeDesignSmall} 768w, ${creativeDesign} 1200w`} className='service-card-image' alt='creative-design-card-image'/>
                    </div>
                    <div className='card-overlay'/>
                    <Button variant="outline-secondary" className='service-card-button' href="./creative-design" target='_blank' alt='creative-design-card-image' >View Details</Button>
                    <Card.Body className="services-body">
                    <Card.Title className="services-title">Creative Design</Card.Title>
                    </Card.Body>
                </Card>
                
                <Card className="services-cards" >
                    <div className="card-image-div">
                        <Card.Img variant="top" src={accounting} srcSet={`${accountingSmall} 768w, ${accounting} 1200w`} className='service-card-image' alt='accounting-card-image' />
                    </div>
                    <div className='card-overlay'/>
                    <Button variant="outline-secondary" className='service-card-button' href="./accounting" target='_blank' alt='accounting-card-image' >View Details</Button>
                    <Card.Body className="services-body">
                    <Card.Title className="services-title">Accounting</Card.Title>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </div>
    );
};
export default WhatWeDo;
