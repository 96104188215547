import React from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap';

import introArrows from '../website-images/introArrows.svg';
import salesRepresentative from '../website-images/sales-representative.webp';
import emailChatSupport from '../website-images/email-chat-support.webp';
import leadsGeneration from '../website-images/leads-generation.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';

export default function SalesPage() {
  return (
    <div className='sales-page-container'>
        <Helmet>
          <title>Sales Services | Creaxon Inc</title>
          <meta 
          name="description" 
          content="Our Sales team are highly trained individuals that can interact with the prospected customers with ease."
          />
          <link rel="canonical" href='https://www.creaxoninc.com/sales' />
      </Helmet>

        <div className='sales-page-banner'></div>
        <div className='sales-page-title'><h1>Sales</h1></div>

        <Container className='sales-page-subtitle'>
            <Row className='sales-page-subtitle-row'>
            <Col className='sales-page-description col-12 col-md-6'>
                <p>Sales are one of the most important things a business needs in order to thrive. Our Sales team are highly trained individuals that can interact with the prospected customers with ease. 
                <br/>
                <br/>
                We cultivate a positive environment for our sales team which also helps them to create good relationships with customers resulting to trust and long-lasting partnership leading to more profit. </p>
            </Col>
            <Col className='sales-page-quote col-12 col-md-6'>
                <hr/>
                <h2>“Great salespeople are relationship builders who provide value and help their customers win”</h2>
                <br/>
                <h2 className='sales-page-quote-author'>- Jeffrey Gitomer</h2>
                <hr/>
                <div className='sales-page-description-logo'><Image src={introArrows} alt='introArrows.svg' className='sales-page-img'/></div>
            </Col>
            <Col className='sales-page-description-2 col-12'>
                <h2>Why Creaxon Inc?</h2>
                <p>Here at Creaxon Inc., Our sales team is focused on delivering high-quality service, support, and experience when it comes to liaising with customers. We assure that all leads are being touched on ensuring that all proper communication is being done to entice the prospected customers to engage in a transaction with your business. 
                <br/>
                <br/>
                We provide proper training to our sales team to make sure that they can handle the job well whatever products they are selling. </p>
            </Col>
            <Col className='sales-page-description-3 col-12 col-md-6'>
                <h2>Continous Product Training</h2>
                <p>The team leaders and managers are working hand-in-hand to monitor the quality of service. The team is required to attend to product training whether it be domestic or international to keep the product knowledge up to date.</p>
            </Col>
            <Col className='sales-page-description-4 col-12 col-md-6'>
                <h2>Access the best resources</h2>
                <p>Proper tools and access are being provided to our sales team ensuring that their tasks are being performed in an efficient and timely manner. Calls, emails, and chats are being monitored to make sure that they are being routed to the people with the right skills. </p>
            </Col>
            <Col className='sales-page-description-5 col-12 col-md-6'>
                <h2>Leverage industry best practices</h2>
                <p>We make sure that the global standard when it comes to customer service is strictly followed.</p>
            </Col>
            </Row>
        </Container>

        {/* CARDS SECTION */}
        <Container className='sales-page-cards-container'>
        <Row>

            <Col className='sales-page-card-item col-12 col-lg-6'>
            <div className='sales-page-card-item-img'>
                <Image src={salesRepresentative} alt='sales-representative.jpg' fluid/>
            </div>
            </Col>

            <Col className='sales-page-card-item col-12 col-lg-6'>
            <div className='sales-page-card-item-title'>
                <h2>Sales Representative</h2>
                <p>Sales reps serve as the point of contact between a business and its prospects or clients and have  a range of responsibilities including  identifying and educating prospective customers while supporting existing clients with information and assistance that relates to products and services. 
                <br/>
                <br/>
                Creaxon can provide staffing solutions for Sales Representatives with skills ranging from (but not limited to) the following:</p>
                <ul>
                    <li>Customer services skills to listen to the concerns of a customer and be able to address their needs.</li>
                    <li>Confidence and strong self-assuredness to succeed in cold-calling customeres and making a sale.</li>
                    <li>Communication skills, especially persuasion, to clearly convey the benfits of a priduct to potential customers</li>
                </ul>
            </div>
            </Col>

            <Col className='sales-page-card-item col-12 order-2 col-lg-6 order-lg-1'>
            <div className='sales-page-card-item-title'>
                <h2>Email / Chat Support</h2>
                <p>The Specialists will be obliged to maintain the company's worldwide reputation by serving on the frontline of customer experience with excelent communication skills and providing satisfactory solutions to a diverse range of queries.
                <br/>
                <br/>
                Creaxon Inc. can source for you Email/Chat Specialists with skills ranging (but not limited to) the following:</p>
                <ul>
                    <li>Prompt chat and email response to customers</li>
                    <li>Document each customer's question or problem as well as the resulting answer or solution</li>
                    <li>Develop customer service solutions</li>
                    <li>Maintain service level</li>
                </ul>
            </div>
            </Col>

            <Col className='sales-page-card-item col-12 order-1 col-lg-6 order-lg-2'>
            <div className='sales-page-card-item-img'>
                <Image src={emailChatSupport} alt='email-chat-support.jpg' fluid/>
            </div>
            </Col>

            <Col className='sales-page-card-item col-12 order-3 col-lg-6'>
            <div className='sales-page-card-item-img'>
                <Image src={leadsGeneration} alt='leads-generation.jpg' fluid/>
            </div>
            </Col>

            <Col className='sales-page-card-item col-12 order-4 col-lg-6'>
            <div className='sales-page-card-item-title'>
                <h2>Leads Generation</h2>
                <p>The Sales Lead Generation Specialist is responsible for prospecting, qualifying and generating new sales leads to support the Regional Sales Directors and Marketing Director. This candidate will be a highly motivated, self-starter and able to identify and develop new business prospects from multiple sources including inbound marketing leads, prospect lists, discovery and individual research. 
                <br/>
                <br/>
                Creaxon Inc, cab source for lead generation experts with skills ranging from (but not limited to) the following:</p>
                <ul>
                    <li>Appointment Setting</li>
                    <li>Customer acquisition</li>
                    <li>Lead generation</li>
                    <li>Outbound sales</li>
                    <li>Social Media</li>
                    <li>Lead Research</li>
                </ul>
            </div>
            </Col>
        </Row>
      </Container>

    </div>
  )
}
