import { React, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//UNIVERSAL COOKIE for Cokkie Management
import Cookies from 'universal-cookie';

//TOASTIFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import infoIcon from './website-images/information-icon.svg';
import errorInfoIcon from './website-images/errorInfoIcon.png';

// IMPORTED COMPONENTS
import AppNavbar from './components/AppNavbar';
import Footer from './components/Footer';
import ConsulationModal from './components/ConsulationModal';

//  IMPORTED PAGES
import HomePage from './pages/HomePage';
import OurServicesPages from './pages/OurServicesPage';
import HowItWorksPage from './pages/HowItWorksPage';
import CareersPage from './pages/CareersPage';
import AboutUsPage from './pages/AboutUsPage';
import AdminSupportPage from './pages/AdminSupportPage';
import MarketingPage from './pages/MarketingPage';
import WebDevelopmentPage from './pages/WebDevelopmentPage';
import SalesPage from './pages/SalesPage';
import CreativeDesignPage from './pages/CreativeDesignPage';
import AccountingPage from './pages/AccountingPage';
import ContactUsPage from './pages/ContactUsPage';

// REACT GOOGLE ANALYTICS
import ReactGoogleAnalytics  from 'react-ga4';
import OurProjectsPage from './pages/OurProjectsPage';

// INITIALIZATION OF REACT GOOGLE ANALYTICS
ReactGoogleAnalytics.initialize([{trackingId: "G-NH9ZHD0V9Z"}]);
ReactGoogleAnalytics.send({ hitType: "pageview", page: window.location.pathname });



const App = () => {
  const [modalShow, setModalShow] = useState(false);

  //handles the state for the mesage success or message failed toast.

  //handles the hiding of modal.
  function onHide() {
    return setModalShow(false);
  }

  //GOOGLE ANALYTICS CUSTOM EVENTS

  //custom events for buttons
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGoogleAnalytics.event({
      category: category,
      action: action,
      label: label,
    })
  };

  
  //Toast Message
const MsgSuccess = () => (
  <div>
    <h6><strong>Message Sent!</strong></h6>
    <p>Our representatives will reach out to you shortly via email or phone.</p>
    <hr />
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
    <span><img src={infoIcon} alt='info-icon' style={{width: '30px', height: '30px'}}/></span>
    <p style={{marginLeft: '10px', display: 'inline'}}>Please check your Junk/Spam folder if the email confirmation is not on your Inbox.</p>
    </div>
  </div>
)

const MsgFailed = () => (
  <div>
    <h6><strong>Message Not Sent!</strong></h6>
    <p>We encountered some error on our end. Please Try again later.</p>
    <hr />
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
    <span><img src={errorInfoIcon} alt='info-icon' style={{width: '30px', height: '30px'}}/></span>
    <p style={{marginLeft: '10px', display: 'inline'}}>Please check your internet connection also and see if the error still occurs.</p>
    </div>
  </div>
)

  const notifySuccess = () => toast(<MsgSuccess />, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: false,
    });

    const notifyFailed = () => toast.error(<MsgFailed />, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
      });
      
      //CODE for setting cookies to sameSite: none
      const cookies = new Cookies();
      cookies.set('creaxonIncCookies', 'cr3@x0n!nC@2022', {path: '/', secure: true, sameSite: 'none'});

  return (
    <div>
      <BrowserRouter>
        <AppNavbar />
        <Routes>
          <Route path="/" element={ <HomePage eventTrack={eventTrack}/> }/>
          <Route path="/our-services" element={ <OurServicesPages/> }/>
          <Route path="/careers" element={ <CareersPage/> }/>
          <Route path="/how-it-works" element={ <HowItWorksPage/> }/>
          <Route path="/about-us" element={ <AboutUsPage/> }/>

          <Route path="/admin-support" element={ <AdminSupportPage/> }/>
          <Route path="/marketing" element={ <MarketingPage/> }/>
          <Route path="/web-development" element={ <WebDevelopmentPage/> }/>
          <Route path="/sales" element={ <SalesPage/> }/>
          <Route path="/creative-design" element={ <CreativeDesignPage/> }/>
          <Route path="/accounting" element={ <AccountingPage/> }/>
          <Route path="/our-projects" element={ <OurProjectsPage/> }/>
          <Route path="/contact-us" element={ <ContactUsPage notifyToastSuccess={notifySuccess} notifyToastFailed={notifyFailed} eventTrack={eventTrack}/> }/>
        </Routes>
      <Footer eventTrack={eventTrack} />
      <ConsulationModal 
      show={modalShow}
      onHide={onHide}
      notifyToastSuccess={notifySuccess}
      notifyToastFailed={notifyFailed}
      />
      <ToastContainer className="toast-container"
      style={{width: '500px'}}
      />
      </BrowserRouter>
    </div>
  );
};
export default App;
