import React from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap';

import introArrows from '../website-images/introArrows.svg';
import graphicDesigner from '../website-images/graphic-designer.webp';
import videoEditor from '../website-images/video-editor.webp';
import brandSpecialist from '../website-images/brand-specialist.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';

export default function CreativeDesignPage() {
  return (
    <div className='creativeDesign-page-container'>
        <Helmet>
          <title>Creative Design Services | Creaxon Inc</title>
          <meta 
          name="description" 
          content="Our Creative Design Services will take care of your brand design based on what you want it to be. 
          We have design experts who can create fantastic designs to deliver your brand's message creatively."
          />
          <link rel="canonical" href='https://www.creaxoninc.com/creative-design' />
        </Helmet>

        <div className='creativeDesign-page-banner'></div>
        <div className='creativeDesign-page-title'><h1>Creative Design</h1></div>

        <Container className='creativeDesign-page-subtitle'>
            <Row className='creativeDesign-page-subtitle-row'>
            <Col className='creativeDesign-page-quote col-12 col-md-6'>
                <hr/>
                <h2>“Design is the silent ambassador for your brand”</h2>
                <br/>
                <h2 className='creativeDesign-page-quote-author'>- Paul Rand</h2>
                <hr/>
                <div className='creativeDesign-page-description-logo'><Image src={introArrows} alt='creaxon-arrows' className='creativeDesign-page-img'/></div>
            </Col>
            <Col className='creativeDesign-page-description col-12 col-md-6'>
                <p>In today's world, great storytelling will not be noticed as is. It needs a proper medium to carry it to people's attention. Creative Design can entice the customers to pay attention to what you are selling. 
                <br/>
                <br/>
                Our Creative Design Services will take care of your brand design based on what you want it to be. We have design experts who can create fantastic designs to deliver your brand's message creatively. </p>
            </Col>
            </Row>
        </Container>

        {/* CARDS SECTION */}
        <Container className='creativeDesign-page-cards-container'>
        <Row>

            <Col className='creativeDesign-page-card-item col-12 col-lg-6'>
            <div className='creativeDesign-page-card-item-img'>
                <Image src={graphicDesigner} alt='graphic-designer.jpeg' fluid/>
            </div>
            </Col>

            <Col className='creativeDesign-page-card-item col-12 col-lg-6'>
            <div className='creativeDesign-page-card-item-title'>
                <h2>Graphic Designer</h2>
                <p>Graphic designers create visual text and imagery concepts, by hand or using computer software, to communicate ideas that inspire, inform, or captivate consumers.
                <br/>
                <br/>
                Creaxon can provide staffing solutions for Graphic Design services with skills ranging from (but not limited to) the following:</p>
                <ul>
                    <li>Knowledge of design techniques, tools, and principles</li>
                    <li>Typography knowledge</li>
                    <li>Multimedia content development</li>
                    <li>Creativity and Originality</li>
                    <li>Fluency of ideas</li>
                    <li>Attention to detail</li>
                    <li>Multitasking</li>
                </ul>
            </div>
            </Col>

            <Col className='creativeDesign-page-card-item col-12 order-2 col-lg-6 order-lg-1'>
            <div className='creativeDesign-page-card-item-title'>
                <h2>Video Editor</h2>
                <p>Video Editors are generally responsible for editing and assembling recorded raw material into a suitable, finished product ready for broadcasting. The material may include camera footage, dialogue, sound effects, graphics and special effects.</p>
                <ul>
                    <li>Experience using video editing equipment</li>
                    <li>Creative thinker</li>
                    <li>Good tike-management skills</li>
                    <li>Great interpersonal and communication skills</li>
                    <li>Proficient in Final Cut Pro X, Adobe After Effects, Adobe Premiere</li>
                    <li>Knowledge and a good understanding of motiion graphics</li>
                </ul>
            </div>
            </Col>

            <Col className='creativeDesign-page-card-item col-12 order-1 col-lg-6 order-lg-2'>
            <div className='creativeDesign-page-card-item-img'>
                <Image src={videoEditor} alt='video-editor.jpeg' fluid/>
            </div>
            </Col>

            <Col className='creativeDesign-page-card-item col-12 order-3 col-lg-6'>
            <div className='creativeDesign-page-card-item-img'>
                <Image src={brandSpecialist} alt='brand-specialist.jpeg' fluid/>
            </div>
            </Col>

            <Col className='creativeDesign-page-card-item col-12 order-4 col-lg-6'>
            <div className='creativeDesign-page-card-item-title'>
                <h2>Brand Specialist</h2>
                <p>Brand Specialists help define the way that consumers think about company's goods or services; this can involve consistency of logos and colors, focused advertising spaces, event sponsorships, and other approaches.</p>
                <ul>
                    <li>Developing marketing plans and strategies to increase brand awareness and promote products</li>
                    <li>Conducting market research and gathering information about potential customer's preferences, needs, and buying habits</li>
                    <li>Creating and managing marketing plans that include advertising campaigns, public relation initiatives, and other efforts to promote brands and products</li>
                    <li>Recommending changes to existing products or developing new products based on market trends and customer feedback</li>
                    <li>Coordinating with Sales Teams to promote new products or services to existing clients</li>
                    <li>Creating advertisments for prints and online publications such as trade journals or social media sites</li>
                </ul>
            </div>
            </Col>
        </Row>
      </Container>

    </div>
  )
}

