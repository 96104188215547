import { React, useState } from 'react';

// IMPORT COMPONENTS
import Banner from '../components/Banner';
import ContactUsSection from '../components/ContactUsSection';
import Introduction from '../components/Introduction';
import OurServices from '../components/OurServices';
import CareersJoinUs from '../components/CareersJoinUs';
import ConsulationModal from '../components/ConsulationModal';

//TOASTIFY
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import infoIcon from '../website-images/information-icon.svg';
import errorInfoIcon from '../website-images/errorInfoIcon.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';

export default function HomePage(props) {

    const [modalShow, setModalShow] = useState(false);

    //handles the state for the mesage success or message failed toast.
  
    //handles the hiding of modal.
    function onHide() {
      return setModalShow(false);
    }
    //handles the showing of modal.
    function onShow() {
      return setModalShow(true);
    }

  //Toast Message
  const MsgSuccess = () => (
    <div>
      <h6><strong>Message Sent!</strong></h6>
      <p>Our representatives will reach out to you shortly via email or phone.</p>
      <hr />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
      <span><img src={infoIcon} alt='info-icon' style={{width: '30px', height: '30px'}}/></span>
      <p style={{marginLeft: '10px', display: 'inline'}}>Please check your Junk/Spam folder if the email confirmation is not on your Inbox.</p>
      </div>
    </div>
  )
  
  const MsgFailed = () => (
    <div>
      <h6><strong>Message Not Sent!</strong></h6>
      <p>We encountered some error on our end. Please Try again later.</p>
      <hr />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
      <span><img src={errorInfoIcon} alt='info-icon' style={{width: '30px', height: '30px'}}/></span>
      <p style={{marginLeft: '10px', display: 'inline'}}>Please check your internet connection also and see if the error still occurs.</p>
      </div>
    </div>
  )
  
    const notifySuccess = () => toast(<MsgSuccess />, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
      });
  
      const notifyFailed = () => toast.error(<MsgFailed />, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: false,
        });


  return (
    <div>
      <Helmet>
        <title>Creaxon Inc | Home</title>
        <meta name="description"  content="Build your team with top-performing offshore staff such as admin, marketing, web, sales, graphics and accounting support." />
        <link rel="canonical" href='https://www.creaxoninc.com/' />

        <meta property="og:title" content="Creaxon Inc" />
        <meta property="og:type" content="website" />
        <meta property="og:description"  content="We offer different offshore services ranging from Web Development, Marketing, Accounting, Sales, Admin, Creative Design, to Accounting services." />
        <meta property="og:url" content="https://www.creaxoninc.com/" />
        <meta property="og:image" content="https://www.creaxoninc.com/website-images/bannerBackground2-1200x675.webp" />

      </Helmet>
      
        <Banner
        onHide={onHide}
        onShow={onShow}
        eventTrack={props.eventTrack}
        />
        <Introduction/>
        <OurServices/>
        <ContactUsSection
        onHide={onHide}
        onShow={onShow}
        />

        {/* <CareersJoinUs/> */}
        <ConsulationModal 
      show={modalShow}
      onHide={onHide}
      notifyToastSuccess={notifySuccess}
      notifyToastFailed={notifyFailed}
      eventTrack={props.eventTrack}
      />
    </div>
  )
}
