import React from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import aboutUsTitleImg from '../website-images/about-us-title-img.webp';
import handOnHeartIcon from '../website-images/hand-in-heart-icon.svg';
import lightBulbIcon from '../website-images/light-bulb-icon.svg';
import targetIcon from '../website-images/target-icon.svg';
import introArrows from '../website-images/introArrows.svg';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';

function AboutUsPage() {
  return (
    <div className='about-us-page-container'>
      <Helmet>
        <title>Creaxon Inc | About Us</title>
        <meta 
        name="description" 
        content="Creaxon Inc. is an outsourcing company that aims to enable business efficiency & productivity, corporate competitiveness and solvency."
        />
        <link rel="canonical" href='https://www.creaxoninc.com/about-us' />
      </Helmet>

      {/* ABOUT US TITLE SECTION */}
      <Container className='about-us-title-container' fluid>
        <Row>
          <Col className='about-us-title col-12 col-md-6'>
          <h1>About <span>Us</span></h1>
          <p>Creaxon Inc. is an outsourcing company guided by the strong need to reduce corporate costs, enable business efficiency & productivity, corporate competitiveness and solvency.</p>
          <Image className='about-us-page-title-arrow' src={introArrows} alt='creaxon-arrows' fluid/>
          </Col>
          <Col className='about-us-title-images col-12 col-md-6'>
          <Image className='about-us-title-image' src={aboutUsTitleImg} alt="about-us-title-image" fluid />
          </Col>
        </Row>
      </Container>

      {/* ABOUT US CARDS SECTION */}
      <div className='about-us-cards-container'>
        
        <div className='about-us-card'>
          <div className='about-us-card-img-1'></div>
          <div className='about-us-card-info-1'>
            <h2>Together, we go forward</h2>
            <p>We create innovative solutions for we remain committed to enable clients' success by providing a world class customer experience at every interaction and every opportunity.</p>
          </div>
        </div>
        <div className='about-us-card'>
        <div className='about-us-card-info-2'>
            <h2>Innovation through Collaboration</h2>
            <p>Located in the Philippines, we're a company redefining the BPO space by providing full time staff for your admin/support,  marketing, web development, sales support, and creative design needs.</p>
          </div>
          <div className='about-us-card-img-2'>
          
          </div>
        </div>

      </div>

      {/* ABOUT US VISION SECTION */}
      <Container className='about-us-visions-container' >

        <Row className='about-us-vision'>
          <Col className='about-us-vision-img col-12 col-md-2'><Image className='vision-icon' src={lightBulbIcon} alt='light-bulb-icon' fluid/></Col>
          <Col className='about-us-vision-info col-12 col-md-10'>
            <h2>Inspired by Global Experiences</h2>
            <p>Our vision is to be the world's leading offshore business partner in the digital age, providing our clients growth and innovation through our world-class highly skilled workforce.</p>
          </Col>
        </Row>

        <Row className='about-us-vision'>
          <Col className='about-us-vision-img col-12 col-md-2'><Image className='vision-icon' src={handOnHeartIcon} alt='hand-on-heart-icon' fluid /></Col>
          <Col className='about-us-vision-info col-12 col-md-10'>
            <h2>Fueled by Hard Work & Passion</h2>
            <p>Our employees, our clients, and their customers are the heart of everything we do. We pride ourselves on having a team that works together for the betterment of everyone.</p>
          </Col>
        </Row>

        <Row className='about-us-vision'>
          <Col className='about-us-vision-img col-12 col-md-2'><Image className='vision-icon' src={targetIcon} alt='target-icon' fluid /></Col>
          <Col className='about-us-vision-info col-12 col-md-10'>
            <h2>Result Driven</h2>
            <p>When you work with us, we're not just thinking about the outcome of your business today - but when it grows, and how you'll benefit from that growth in the future.</p>
          </Col>
        </Row>

      </Container>



    </div>
  )
}

export default AboutUsPage