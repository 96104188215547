import React from 'react';
import {Image, Container, Col, Row} from 'react-bootstrap';

import introArrows from '../website-images/introArrows.svg';
import virtualAssistant from '../website-images/virtual-assistant.webp';
import customerService from '../website-images/customer-service-representative.webp';
import dataEntry from '../website-images/data-entry-specialist.webp';

//IMPORTS FOR SEO and META DATA
import {Helmet} from 'react-helmet-async';



function AdminSupportPage() {
  return (
    <div className='adminSupport-page-container'>
        <Helmet>
            <title>Admin Support Services | Creaxon Inc</title>
            <meta 
            name="description" 
            content="We help companies assemble a great team to deliver an outstanding Customer Service and Admin Support needed for your business."
            />
            <link rel="canonical" href='https://www.creaxoninc.com/admin-support' />
        </Helmet>

        <div className='adminSupport-page-banner'></div>
        <div className='adminSupport-page-title'><h1>Admin / Support</h1></div>

        <Container className='adminSupport-page-subtitle'>
            <Row className='adminSupport-page-subtitle-row'>
            <Col className='adminSupport-page-description col-12 col-md-6'>
                <p>We help companies assemble a great team to deliver an outstanding Customer Service and Admin Support needed for your business. 
                <br/>
                <br/>
                In this digital age of automation, we believe that it is  important to create genuine human connection with your customers to drive brand loyalty and achieve your revenue goals. </p>
            </Col>
            <Col className='adminSupport-page-quote col-12 col-md-6'>
                <hr/>
                <h2>“ When the customer comes first, the customer will last. ”</h2>
                <br/>
                <h2 className='adminSupport-page-quote-author'>- Robert Half</h2>
                <hr/>
                <div className='adminSupport-page-description-logo'><Image src={introArrows} alt='creaxon-arrows' className='adminSupport-page-img' /></div>
            </Col>
            </Row>
        </Container>

        <div className='adminSupport-page-subtitle-2'>
            <h2>Transform your Business</h2>
            <p>Customers expect outstanding experience every time. Our team prides itself on making sure we provide world-class service on every touchpoint.  We'll help you build a robust customer-centric structure so you can achieve a sustainable growth for your business. Embrace a role that combines your expertise in Australian customer service with office-based coordination. As a Service Coordinator, you'll be a vital link between our clients and our skilled team of professionals, ensuring seamless service delivery and customer satisfaction.</p>
        </div>
        
        {/* CARDS SECTION */}
        <Container className='adminSupport-page-cards-container'>
            <Row>

                <Col className='adminSupport-page-card-item col-12 col-lg-6'>
                <div className='adminSupport-page-card-item-img'>
                    <Image src={virtualAssistant} alt='virtual-assistant.jpg' fluid />
                </div>
                </Col>

                <Col className='adminSupport-page-card-item col-12 col-lg-6'>
                <div className='adminSupport-page-card-item-title'>
                    <h2>Virtual Assistant</h2>
                    <p>A virtual assistant is trained and experienced to help you and your team on a range of tasks. Outsourcing administrative and support functions through offshoring can offer significant cost savings without compromosing on effectiveness nor on productivity.</p>

                    <p>Creaxon can source for your Virtual Assistants with skills ranging from (but not limited to) the following:</p>
                    <ul className='adminSupport-page-card-item-list'>
                        <li>Organizing the workspace</li>
                        <li>Time management</li>
                        <li>Task management</li>
                        <li>Encoding</li>
                        <li>Desktop sharing</li>
                        <li>Proficiency in written and spoken English</li>
                        <li>Online technology (Skype, Google Suite, Dropbox etc.)</li>
                        <li>Word processing and spreadsheets</li>
                    </ul>
                </div>
                </Col>


                <Col className='adminSupport-page-card-item col-12 order-2 col-lg-6 order-lg-1'>
                <div className='adminSupport-page-card-item-title'>
                    <h2>Customer Service Representative</h2>
                    <p>Customer services is at the heart of any business, and getting it right can never be undervalued. Whilst sales and marketing attracts new customers, it is how you treat your existing customers that really drives business success.
                    <br/>
                    <br/>
                    Creaxon can provide staffing solutions across all customer support functions, including (but not limited to) the following:</p>
                    <ul className='adminSupport-page-card-item-list'>
                        <li>Technical Helpdesk</li>
                        <li>Customer Service Representative</li>
                        <li>Customer Support Management</li>
                        <li>Online Customer Services</li>
                        <li>Product Management Specialists</li>
                        <li>Experienced Service Assistants</li>
                    </ul>
                </div>
                </Col>

                <Col className='adminSupport-page-card-item col-12 order-1 col-lg-6 order-lg-2'>
                <div className='adminSupport-page-card-item-img'>
                    <Image src={customerService} alt='customer-service.jpg' fluid />
                </div>
                </Col>

                <Col className='adminSupport-page-card-item col-12 order-3 col-lg-6'>
                <div className='adminSupport-page-card-item-img'>
                    <Image src={dataEntry} alt='data-entry.jpg' fluid />
                </div>
                </Col>

                <Col className='adminSupport-page-card-item col-12 order-4 col-lg-6'>
                <div className='adminSupport-page-card-item-title'>
                    <h2>Data Entry Specialist</h2>
                    <p>If you are looking for knowledgeable and experienced data entry specialists, Creaxon Inc. has the expertise you need to provide you with highly suitable candidates.
                    <br/>
                    <br/>
                    Creaxon can provide staffing solutions across all customer support functions, including (but not limited to) the following:</p>
                    <ul className='adminSupport-page-card-item-list'>
                        <li>Data Entry Experts</li>
                        <li>Data Mining Specialist</li>
                        <li>Customer Support Management</li>
                        <li>Online Customer Services</li>
                        <li>Product Management Specialists</li>
                        <li>Experienced Service Assistants</li>
                    </ul>
                </div>
                </Col>

            </Row>
        </Container>
    </div>
  )
}

export default AdminSupportPage